import firebaseService from '@ameroservices-platform/shared/services/firebase';
import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ButtonGroup } from '@mui/material';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import SpinnerLoading from '@ameroservices-platform/shared/ui-components/FuseLoading';
import FuseAnimateAdapter from '@ameroservices-platform/shared/ui-components/FuseAnimateAdapter';
import withRouter from '@ameroservices-platform/attraction-flexposui/fuse/core/withRouter';
import { logoutUser } from '@ameroservices-platform/attraction-flexposui/app/auth/store/userSlice';
import { getParameterByName } from '../../AttUtils';
import flexposApiService from '@ameroservices-platform/shared/services/flexsdk';

class FlexposAuth extends Component {
	constructor(props) {
		super(props);
		// auth: null,
		this.state = { loading: true };
	}

	componentDidMount() {
		firebaseService.onAuthStateChanged(async (auth) => {
			const token = getParameterByName('token', this.props.location.search);
			// this.setState({ auth });
			console.log(auth, token);
			if (auth && token) {
				const idTokenResult = await auth.getIdTokenResult();
				console.log(
					idTokenResult,
					idTokenResult.claims.organisationId && idTokenResult.claims.role !== 'flexposUser'
				);
				if (
					idTokenResult.claims.organisationIds &&
					firebaseService.getOrganisationType() &&
					idTokenResult.claims.organisationIds[firebaseService.getOrganisationType()] &&
					idTokenResult.claims.role !== 'flexposUser'
				) {
					firebaseService
						.callFunctionByName(
							'authPromoteToFlexposUser',
							{
								token,
							},
							true
						)
						.then(() => {
							console.log('promoted');
							firebaseService.refreshToken().then(() => {
								setTimeout(() => {
									window.location.reload();
								}, 1000);
							});
						})
						.catch((e) => {
							console.error(e);
						});
				} else {
					this.setState({ loading: false });
				}
				// } else if (
				// 	idTokenResult.claims.organisationIds &&
				// 	firebaseService.getOrganisationType() &&
				// 	idTokenResult.claims.organisationIds[
				// 		firebaseService.getOrganisationType()
				// 	] &&
				// 	idTokenResult.claims.role === 'flexposUser' &&
				// 	idTokenResult.claims.signedSearchKey
				// ) {
				// 	firebaseService.setSignedSearchKey(
				// 		idTokenResult.claims.signedSearchKeys &&
				// 			idTokenResult.claims.signedSearchKeys[
				// 				firebaseService.getOrganisationType()
				// 			]
				// 			? idTokenResult.claims.signedSearchKeys[
				// 					firebaseService.getOrganisationType()
				// 			  ]
				// 			: idTokenResult.claims.signedSearchKey
				// 	);
				// 	this.setState({ loading: false });
				// }
			}
		});
	}

	render() {
		if (!getParameterByName('token', this.props.location.search)) {
			return (
				<div className="flex flex-col flex-1 items-center justify-center p-16">
					<style>{`body { background-color: #ffffff !important; }`}</style>
					<ButtonGroup orientation="vertical" className="fixed top-2 right-2">
						<Button
							className="rounded-none"
							onClick={() => {
								window.localStorage.clear();
								window.location.reload();
							}}
							variant="outlined"
							color="primary"
						>
							Clear Cache
						</Button>
						<Button
							className="rounded-none"
							onClick={() => {
								this.props.logoutUser();
							}}
							variant="outlined"
							color="primary"
						>
							Log ud
						</Button>
						<Button
							className="rounded-none"
							onClick={async () => {
								await flexposApiService.closeBrowser();
							}}
							variant="outlined"
							color="primary"
						>
							Luk
						</Button>
					</ButtonGroup>
					<div className="max-w-512 text-center">
						<FuseAnimateAdapter animation="transition.expandIn" delay={100}>
							<Typography variant="h1" color="inherit" className="font-medium mb-16">
								Fejl
							</Typography>
						</FuseAnimateAdapter>

						<FuseAnimateAdapter delay={500}>
							<Typography variant="h3" color="inherit" className="mb-16 text-black">
								Token mangler i URL
							</Typography>
						</FuseAnimateAdapter>

						<FuseAnimateAdapter delay={600}>
							<Typography variant="h6" color="inherit" className="mb-48 text-black">
								Kontakt venligst Amero Support
							</Typography>
						</FuseAnimateAdapter>

						<FuseAnimateAdapter delay={800}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => {
									window.location.reload();
								}}
								className="rounded-none"
							>
								Genindlæs siden
							</Button>
						</FuseAnimateAdapter>
						<FuseAnimateAdapter delay={1000}>
							<Typography color="textSecondary" className="mt-48">
								<strong>Tidspunkt for fejl:</strong> {moment().format('DD-MM-YYYY HH:mm:ss')}
							</Typography>
						</FuseAnimateAdapter>
					</div>
				</div>
			);
		}
		if (this.state.loading) {
			return (
				<>
					<ButtonGroup orientation="vertical" className="fixed top-2 right-2">
						<Button
							className="rounded-none"
							onClick={() => {
								window.localStorage.clear();
								window.location.reload();
							}}
							variant="outlined"
							color="primary"
						>
							Clear Cache
						</Button>
						<Button
							className="rounded-none"
							onClick={() => {
								this.props.logoutUser();
							}}
							variant="outlined"
							color="primary"
						>
							Log ud
						</Button>
						<Button
							className="rounded-none"
							onClick={async () => {
								await flexposApiService.closeBrowser();
							}}
							variant="outlined"
							color="primary"
						>
							Luk
						</Button>
					</ButtonGroup>
					<SpinnerLoading />
				</>
			);
		}
		return (
			<>
				{this.props.children}
				{/* <Typography className="text-xs fixed bottom-2 right-2"> */}
				{/*	Uid: {this.state.auth ? this.state.auth.uid : '-'} */}
				{/* </Typography> */}
			</>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logoutUser,
		},
		dispatch
	);
}
export default connect(null, mapDispatchToProps)(withRouter(FlexposAuth));
